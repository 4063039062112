.img {
    &_card {
        width: 100%;
        background-color: #e0e0e0;

        &_text {
            // padding: 0.5rem;
        }

        &_btn_container {
            display: none;
        }

        &:hover {
            & .img_card_btn_container {
                display: flex;
                justify-content: space-around;
                align-items: flex-end;
                padding: 0.5rem;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
        }
    }

    &_wrapper {
        background-repeat: no-repeat;
        width: 100%;
        height: 120px;
        background-size: contain;
        background-position: center;
        // background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;

        &.raf-h-xl {
            height: 500px;
        }
    }


}
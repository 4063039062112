button {
  all: unset;
  cursor: pointer;
}

.risk-matrix__container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.risk-matrix {
  width: 90%;
  display: grid;
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  box-sizing: inherit;
}

.risk-matrix__levels {
  display: grid;
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  box-sizing: inherit;
}

.risk-matrix__row {
  width: 100%;
  height: 3.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.risk-matrix__row> :first-child {
  border-left: 1px solid;
}

.risk-matrix__row__cell {
  height: 3.25rem;
  position: relative;
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 1px;
  border-style: solid solid solid none;
  font-weight: 400;
  font-size: 12px;
}

.risk-matrix__row__cell__icon {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.risk-matrix__row__cell__icon:focus {
  outline: none;
}

.risk-matrix__row__cell__input {
  width: 2rem;
  padding: 0 0.25rem;
  text-align: center;
}

.risk-matrix__xaxis {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: inherit;
}

.risk-matrix__xaxis> :first-child {
  border-left: 1px dotted;
}

.risk-matrix__label {
  height: 100%;
  padding: 0.25rem;
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 1px;
  border-style: dotted dotted none none;
  font-size: 10px;
  letter-spacing: 0.01rem;
  text-align: center;
  box-sizing: inherit;
  font-style: italic;
  font-weight: 400;
}

.risk-matrix__label--axis {
  background-color: #e3e3e7;
}

.risk-matrix__label--category {
  background-color: #333;
  color: #ccc;
  border: none;
  font-size: 1rem !important;
  font-weight: 600 !important;
  font-style: normal;
}

.risk-matrix__label--xcategory {
  background-color: #ccc;
  border: 1px solid black;
  border-top: none;
  font-style: normal;
  font-weight: 400;
}

.risk-matrix__label--xcategory:first-child {
  border-top: 1px solid black;
}

.risk-matrix__axis__label__input {
  width: 100%;
  height: 100%;
  padding: 0 0.25rem;
  resize: none;
}

.risk-matrix__xaxis__title {
  height: 2.5rem;
}

.risk-matrix__yaxis__title {
  padding: 0.25rem 1rem !important;
}

.risk-matrix__action__container {
  text-align: center;
  display: flex;
}

.risk-matrix__action__container--row {
  align-items: center;
}

.risk-matrix__action__container--col {
  align-items: center;
  justify-content: center;
}

.risk-matrix__action {
  padding: 0.5rem 0.75rem;
  width: 100%;
  max-height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;

  transition: all ease-in-out 125ms;
}

.risk-matrix__action--add {
  border: 2px solid green;
}

.risk-matrix__action--add:hover {
  color: white;
  background-color: green;
  border: 2px solid green;
  transition: all ease-in-out 125ms;
}

.risk-matrix__action--delete {
  border: 2px solid red;
}

.risk-matrix__action--delete:hover {
  color: white;
  background-color: red;
  border: 2px solid red;
  transition: all ease-in-out 125ms;
}

.risk-matrix__btn--long {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.2);

  transition: all ease-in-out 125ms;
}

.risk-matrix__btn--long:hover {
  background-color: rgba(75, 181, 67, 0.8);
  color: white;

  transition: all ease-in-out 125ms;
}

.risk-matrix__btn--add-x {
  display: flex;
  align-items: top;
  justify-content: center;
  color: rgba(0, 0, 0, 0.4);

  transition: all ease-in-out 125ms;
}

.risk-matrix__btn--add-x>svg {
  padding: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.2);

  transition: all ease-in-out 125ms;
}

.risk-matrix__btn--add-x>svg:hover {
  background-color: rgba(75, 181, 67, 0.8);
  color: white;

  transition: all ease-in-out 125ms;
}

.risk-matrix__btn--add-y {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.4);

  transition: all ease-in-out 125ms;
}

.risk-matrix__btn--add-y>svg {
  padding: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.2);

  transition: all ease-in-out 125ms;
}

.risk-matrix__btn--add-y>svg:hover {
  background-color: rgba(75, 181, 67, 0.8);
  color: white;

  transition: all ease-in-out 125ms;
}

.unselectable {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

[contenteditable]:focus {
  background: #fff;
  outline: none;
}

.risk-matrix__row__cell.unselectable {
  cursor: pointer;
}

.risk-matrix__row__cell.active {
  box-shadow: 0 0 0 4px var(--tertiary-70);
  border-radius: 0.25rem;
  z-index: 100;
}

.risk-matrix__row__cell:hover span {
  font-size: 13px;
  font-weight: 600;
}

//risk_matrix_container style changes updated

.risk_matrix {
  &_container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1px;
    width: 100%;
  }

  &_header {
    padding: 10px;
    text-align: center;
    border: 1px solid var(--stroke-neutral-base);
    font: var(--subtitle2-regular);
    color: var(--content-neutral-dark);
  }

  &_item {
    padding: 20px;
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--stroke-neutral-base);
    font: var(--body2-semi-bold);
    color: var(--content-neutral-dark);

    &.active {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.4);
      z-index: 100;
      border-radius: 0.25rem;
    }
  }

  &_input {
    display: flex;
    align-items: center;
    height: 100%;

    & input.e-input {
      padding-left: 10px;
      border-radius: 0;
    }
  }

  &_input_field {
    & input.e-input {
      -webkit-text-fill-color: #000 !important
    }
  }
}

.e-input-group .e-input-group-icon,
.e-input-group.e-control-wrapper .e-input-group-icon {
  min-height: 2.375rem;
  min-width: 2.375rem;
}
.e-img-editor-profile,
.e-img-editor-sample .control-wrapper {
    position: relative;
}

.e-img-editor-profile .e-profile {
    /* width: 200px;
    height: 200px; */
    /* position: absolute; */
    /* left: 50%; */
    /* -webkit-transform: translate(-50%, 30%);
    transform: translate(-50%, 30%); */
    border-radius: 50%;
}

.e-custom-wrapper {
    position: relative;
}

.e-custom-edit {
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: blue;
    top: calc(100% - 54px);
    left: calc(100% - 47px);
}

#custom-edit .e-custom-icon::before {
    content: '\e911';
    font-size: 28px;
    color: white;
    left: 5px;
    top: 4px;
    line-height: initial;
    position: absolute;
}

.tailwind .e-custom-icon::before,
.tailwind-dark .e-custom-icon::before {
    top: 6px;
}

#custom-edit:hover {
    cursor: pointer;
}

canvas#img-canvas {
    border-radius: 50%;
    border: 4px solid darkgrey;
    max-width: 200px;
    max-height: 200px;
}

.e-img-custom-open {
    float: left;
    margin-left: 0 !important;
}

.e-img-custom-reset {
    float: left;
}

.e-profile .e-custom-img,
.e-profile .e-custom-file {
    display: none;
}

.e-bigger #profile-dialog .e-dlg-header {
    font-size: 18px !important;
}

#profile-dialog .e-dlg-content>div {
    height: 100%;
    width: 100%;
}

.control-section.e-img-editor-profile {
    min-height: 350px !important;
}

.highcontrast .e-custom-img-btn,
.fabric .e-custom-img-btn,
.fabric-dark .e-custom-img-btn {
    padding: 2px 10px !important;
}

.e-bigger .e-custom-img-btn {
    font-size: 14px !important;
    padding: 6px 10px !important;
}

.e-bigger.highcontrast .e-custom-img-btn,
.e-bigger.fabric .e-custom-img-btn,
.e-bigger.fabric-dark .e-custom-img-btn,
.e-bigger.material .e-custom-img-btn,
.e-bigger.material-dark .e-custom-img-btn {
    padding: 3px 10px !important;
}

.e-bigger.bootstrap .e-custom-img-btn,
.e-bigger.bootstrap-dark .e-custom-img-btn {
    padding: 1px 10px !important;
}

.e-img-editor-profile .e-hide {
    display: none;
}

.material3 .e-custom-img-btn,
.material3-dark .e-custom-img-btn,
.e-bigger.material3 .e-custom-img-btn,
.e-bigger.material3-dark .e-custom-img-btn {
    margin-left: 3px !important;
}
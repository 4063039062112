.input-btn {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font-size: normal;
	cursor: pointer;
	outline: inherit;
}

.body-part-picker {
	width: 100%;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
}

.body-part-picker__sidebar {
	width: 13rem;
	height: 100%;
	padding: 1rem 0.75rem;
	display: flex;
	flex-direction: column;
	/* background-color: rgb(232, 232, 232); */
	background-color: #D2E9FF;
	overflow-x: hidden;
	overflow-y: auto;
}

.body-part-picker__sidebar__toolbar {
	width: 100%;
	margin-bottom: 0.25rem;
	display: flex;
	align-items: center;
}

.body-part-picker__sidebar__toolbar__search {
	width: 11.5rem;
	height: 1.5rem;
	position: relative;
	display: flex;
	align-items: center;
}

.body-part-picker__sidebar__toolbar__search__input {
	width: 100%;
	height: 100%;
	padding-left: 0.25rem;
	border: solid 1px;
	border-radius: 2px;
	position: absolute;
}

.body-part-picker__sidebar__toolbar__search__btn {
	right: 0;
	position: absolute;
	display: flex;
	align-items: center;
	font-size: 0.7rem;
	text-align: center;
	color: rgba(0, 0, 0, 0.82);
}

.body-part-picker__sidebar__toolbar__search__btn-icon {
	margin: 0 0.15rem;
}

.body-part-picker__sidebar__header {
	width: 100%;
	margin-bottom: 0.5rem;
	font-weight: 600;
	display: flex;
	align-items: center;
}

.body-part-picker__sidebar__header__chevron {
	margin-right: 0.5rem;
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	cursor: pointer;
	outline: inherit;
}

.body-part-picker__sidebar__toggle-section {
	width: 100%;
	padding-left: 1rem;
	display: flex;
	flex-direction: column;
}

.body-part-picker__sidebar__toggle-section__body-part {
	width: 100%;
	margin-bottom: 0.25rem;
	font-size: 0.75rem;
	font-weight: 600;
	display: flex;
	align-items: center;
}

.body-part-picker__sidebar__toggle-section__body-part__checkbox {
	margin-right: 0.5rem;
}

.body-part-picker__main {
	width: calc(100% - 13rem);
	height: 100%;
	padding: 1rem 0 0.5rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
}

.body-part-picker__main__header {
	width: 100%;
	padding: 0 2rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.body-part-picker__main__gender-selection {
	padding-bottom: 1rem;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.body-part-picker__main__gender-selection__btn {
	margin: 0 0.75rem 0 0.75rem;
}

/* .body-part-picker__main__container {
	width: min(95vw, 99.75vh);
	height: min(95vw, 99.75vh);
	position: relative;
} */
.body-part-picker__main__container {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: auto;
}

.body-part-picker__main_name-container {
	height: 3rem;
	padding-bottom: 0.5rem;
	font-weight: 600;
	font-size: 1rem;
}

.body-part-picker__main__container__background-image {
	width: 100%;
	height: 100%;
	position: absolute;
}

.body-part-picker__main__container__svg-canvas {
	width: 100%;
	height: 100%;
	/* position: absolute; */
	transition: all 200ms;
	transform: scale(1);
}

/* .body-part-picker__main__container__svg-canvas:hover {
	transition: all 200ms;
	transform: scale(1.2);
} */

.body-part-picker__main__container__svg-canvas__body-part {
	fill: rgba(248, 75, 75, 0.6);
	/* fill: rgba(0, 0, 0, 0.2); */
}

.body-part--selected {
	/* fill: rgba(222, 95, 169, 0.3); */
	fill: rgba(0, 0, 0, 0.5);
	stroke: red;
	stroke-width: 3px;
	cursor: pointer;
}

.body-part-picker__main__container__svg-canvas__body-part:hover {
	/* fill: rgba(223, 43, 148, 0.5); */
	fill: rgba(0, 0, 0, 0.5);
	stroke: red;
	stroke-width: 3px;
	cursor: pointer;
}

@media only screen and (max-width: 950px){
	.body-part-picker__main{
		width: 100%;
	}

	.body-part-picker__main__container {
		width: min(100%, 100%);
		height: min(100%, 100%);
		position: relative;
	}
	.body-part-picker__sidebar{
		width: 100%;
	}
}
